import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import Container from "components/Container"
import Button from "components/Button"
import { Link } from "gatsby"
import BulletList from "components/BulletList"
import Image from "gatsby-image"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

const METHODS_LIST = [
  "You need a more robust solution to replace your existing spreadsheets",
  "Your current application has not grown with your business and you are ready to remake the app, but with more features and options",
  "You and your team are using multiple pieces of software and are looking for an application to centralize your operations",
]

const SERVICES_LIST = []

const BusinessManagementServices = ({ data }) => (
  <Layout>
    <SEO title="Business Management Software" />

    <Container title="">
      <div
        style={{
          display: `flex`,
          flexFlow: `row wrap`,
          maxWidth: `700px`,
          width: `100%`,
          justifyContent: `space-evenly`,
        }}
      >
        <Container
          title="How to know you are ready to use a custom web based application?"
          tab="Business Management Software"
        >
          <BulletList type={2} list={METHODS_LIST} />
        </Container>

        {/* <Container title="What We Offer" tab="Business Management Software">
          <BulletList
            list={SERVICES_LIST}
            style={{ width: "100%", textAlign: "left" }}
          />
        </Container> */}
      </div>

      <Container title="Pricing" banner notab style={{ margin: "0" }}>
        <p style={{ paddingTop: 0 }}>
          Pricing starts at 125/hr. We use this rate to create your estimate for
          your project. This pricing allows us to focus on your project and not
          require our developers to juggle projects and lose focus.
        </p>
      </Container>

      <Container title="Roadmap" banner notab style={{ margin: "0" }}>
        <p style={{ paddingTop: 0 }}>
          The typical journey from start to finish.
        </p>

        <Image
          fluid={data.projectTimeline.childImageSharp.fluid}
          alt="Software Timeline"
          style={{
            display: `flex`,
            flexFlow: `row wrap`,
            maxWidth: `700px`,
            width: `100%`,
          }}
        />
      </Container>

      <Container title="Next Step" banner notab style={{ margin: "0" }}>
        <p>{`Let's get started with a free initial call.`}</p>
        <Link to="/contact" style={{ margin: "10px 0", maxWidth: "100%" }}>
          <Button style={{ margin: "1rem auto" }}>{`Get in Touch`}</Button>
        </Link>
      </Container>
    </Container>
  </Layout>
)

BusinessManagementServices.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BusinessManagementServices

export const query = graphql`
  query {
    projectTimeline: file(relativePath: { eq: "project-timeline.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
